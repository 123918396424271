import { useEffect } from "react";

import { BrandForThemeFragment } from "../../gql-request";

type BrandThemeProviderProps = {
    brand: BrandForThemeFragment;
};

function hexToHSL(hex: string): { h: number; s: number; l: number } {
    // Remove the hash if present
    hex = hex.replace(/^#/, "");

    // Convert hex to RGB
    const r = parseInt(hex.slice(0, 2), 16) / 255;
    const g = parseInt(hex.slice(2, 4), 16) / 255;
    const b = parseInt(hex.slice(4, 6), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100),
    };
}

function colorToHSLString(hex: string): string {
    const { h, s, l } = hexToHSL(hex);
    return `${h} ${s}% ${l}%`;
}

function createBrandStyles(brand: BrandForThemeFragment): Record<string, string> {
    return {
        // Primary theme colors
        "--primary": colorToHSLString(brand.primaryColour.main),
        "--primary-foreground": colorToHSLString(brand.primaryColour.contrast),

        // Secondary theme colors
        "--secondary": colorToHSLString(brand.secondaryColour.main),
        "--secondary-foreground": colorToHSLString(brand.secondaryColour.contrast),

        // Button colors
        "--button-primary": colorToHSLString(brand.primaryButtonColour.main),
        "--button-primary-foreground": colorToHSLString(brand.primaryButtonColour.contrast),
        "--button-secondary": colorToHSLString(brand.secondaryButtonColour.main),
        "--button-secondary-foreground": colorToHSLString(brand.secondaryButtonColour.contrast),

        // Header colors
        "--header-background": colorToHSLString(brand.headerColour.main),
        "--header-foreground": colorToHSLString(brand.headerColour.contrast),

        // Footer colors
        "--footer-background": colorToHSLString(brand.footerColour.main),
        "--footer-foreground": colorToHSLString(brand.footerColour.contrast),

        // Layout properties
        "--radius": `${brand.roundness}px`,
        //"--spacing": `${brand.airiness * 0.5}rem`,
    };
}

export function BrandThemeProvider({ brand }: BrandThemeProviderProps) {
    useEffect(() => {
        const styles = createBrandStyles(brand);
        const root = document.documentElement;

        const originalStyles = new Map<string, string>();

        Object.entries(styles).forEach(([property, value]) => {
            originalStyles.set(property, root.style.getPropertyValue(property));
            root.style.setProperty(property, value);
        });

        return () => {
            originalStyles.forEach((value, property) => {
                root.style.setProperty(property, value);
            });
        };
    }, [brand]);

    return null;
}
