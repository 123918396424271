import { CacheProvider } from "@emotion/react";
import { Direction, Theme, ThemeProvider } from "@mui/material/styles";
import { ReactNode, createContext, useMemo } from "react";
import { TssCacheProvider } from "tss-react";

import { EmotionCache } from "../../hooks/useEmotionCache";
import { BrandFontLoader, BrandProps } from "./BrandFontLoader";
import { BrandThemeProvider } from "./TailwindBrandThemeProvider";
import { createBrandedTheme } from "./material-ui";

export const BrandedThemeContext = createContext<Theme>(null!);
export type ThemeMode = "HUB" | "BOOKING";
export type BrandedThemeProviderProps = {
    children: ReactNode;
    mode: ThemeMode;
    muiEmotionCache?: EmotionCache;
    tssEmotionCache?: EmotionCache;
    direction?: Direction;
} & BrandProps;

export function BrandedThemeProvider({
    brand,
    children,
    mode,
    muiEmotionCache,
    tssEmotionCache,
    direction = "ltr",
}: BrandedThemeProviderProps) {
    const theme = useMemo(() => {
        return createBrandedTheme({ brand, direction, mode });
    }, [brand, direction, mode]);

    const content = (
        <>
            <BrandFontLoader brand={brand} applyGlobalPrimaryFontStyle={true} />
            <BrandThemeProvider brand={brand} />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </>
    );
    if (muiEmotionCache && tssEmotionCache) {
        return (
            <CacheProvider value={muiEmotionCache}>
                <TssCacheProvider value={tssEmotionCache}>{content}</TssCacheProvider>
            </CacheProvider>
        );
    }
    return content;
}
